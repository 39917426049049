<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import type { Video } from '@/types/video'

defineProps<{
  video: Video
  isVisible?: boolean
}>()

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.greater('lg')
</script>

<template>
  <NuxtPicture
    :img-attrs="{
      class: 'rounded-md',
    }"
    class="absolute"
    src="images/homepage/phone-frame.png"
    alt="Phone frame"
    sizes="lg:134px xl:154px"
    loading="lazy"
  />
  <video
    v-if="isVisible"
    autoplay
    muted
    loop
    playsinline
    preload="none"
    :width="isDesktop ? video.width * 1.16 : video.width"
    :height="isDesktop ? video.height * 1.16 : video.height"
    class="mx-auto mt-1 rounded-b-none rounded-t-2xl object-cover"
    :poster="video.poster"
  >
    <source
      :src="video.url"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
</template>
