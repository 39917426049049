<script setup lang="ts">
defineProps<{
  heading?: string
}>()

const { products } = await useSearch({
  ignoreParamsFromUrl: true,
  sortMethod: [
    'variants.attributes.dspn-car desc',
    'variants.attributes.follower_count_numeric desc',
  ],
})
</script>

<template>
  <GigCarousel :items="products">
    <div class="mb-7 flex items-center justify-between md:mb-3">
      <h2 class="text-2xl text-gray-100">{{ heading }}</h2>
      <BaseSeeAllLink
        variant="blue"
        url="/search/all"
        class="hidden md:block"
      />
    </div>
  </GigCarousel>
  <div class="mt-8 flex justify-center md:hidden">
    <BaseSeeAllLink
      label="View all"
      variant="blue"
      url="/search/all"
    />
  </div>
</template>
