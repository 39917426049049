<script setup lang="ts">
import type { Image } from '@/types/image'

interface Item {
  url?: string
  emitClickStep?: boolean
  title: string
  image: Image
}
defineProps<{
  heading: string
  items: Item[]
}>()
defineEmits<{ (e: 'clickStep'): void }>()

const getWrapperTag = ({ url, emitClickStep }: Item) => {
  if (url) {
    return resolveComponent('NuxtLink')
  } else if (emitClickStep) {
    return 'button'
  }
  return 'div'
}
</script>

<template>
  <div>
    <h2 class="mb-4 text-2xl">{{ heading }}</h2>
    <ol class="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="relative aspect-[352/201] overflow-hidden rounded-md bg-black/30"
      >
        <NuxtPicture
          :src="item.image.url"
          :alt="item.image.alt"
          :img-attrs="{
            class: 'h-full w-full object-cover',
          }"
          :width="item.image.dimensions.w"
          :height="item.image.dimensions.h"
          sizes="md:636px lg:352px"
          loading="lazy"
        />
        <Component
          :is="getWrapperTag(item)"
          :to="item.url"
          class="absolute inset-0 flex h-full w-full border-2 border-transparent bg-transparent p-3.5 text-white"
          :class="{
            'cursor-pointer rounded-md hover:bg-blue-200/40 focus:bg-blue-200/40 focus-visible:border-blue-200':
              item.url || item.emitClickStep,
          }"
          @click="item.emitClickStep ? $emit('clickStep') : undefined"
        >
          <div class="mt-auto flex items-center">
            <span
              class="mr-2.5 grid aspect-square h-[38px] place-items-center rounded-full border border-white bg-black/30 text-2xl"
            >
              {{ index + 1 }}
            </span>
            <span class="text-[28px]">
              {{ item.title }}
            </span>
          </div>
        </Component>
      </li>
    </ol>
  </div>
</template>
