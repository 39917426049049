<script setup lang="ts">
import type { Image } from '@/types/image'
import type { Video } from '@/types/video'

const props = defineProps<{
  video?: Video
  logo: Image
  url?: string
  heading: string
  paragraph: string
  isVisible?: boolean
}>()

const wrapperTag = computed(() =>
  props.url ? resolveComponent('NuxtLink') : 'div',
)
</script>

<template>
  <Component
    :is="wrapperTag"
    :to="url"
    class="flex h-full pt-6 lg:py-2.5"
  >
    <div
      class="flex w-full flex-col items-center rounded-md bg-blue-100 px-4 pb-8 lg:h-[305px] lg:flex-row-reverse lg:justify-end lg:gap-8 lg:px-8"
    >
      <div
        class="flex w-full items-center justify-center gap-6 md:gap-10 lg:gap-14"
      >
        <div
          v-if="video"
          class="relative -top-4 aspect-[134/280] h-[280px] lg:top-4 lg:h-[325px]"
        >
          <CmsVideoPhoneFrame
            :is-visible="isVisible"
            :video="video"
          />
        </div>
        <NuxtPicture
          :img-attrs="{
            class:
              'rounded-md object-cover lg:w-auto w-full h-auto max-w-[210px]',
          }"
          :src="logo.url"
          :alt="logo.alt"
          :width="logo.dimensions.w"
          :height="logo.dimensions.h"
          sizes="sm:420px"
          loading="lazy"
          class="min-w-[147px] md:w-[210px]"
        />
      </div>

      <div class="max-w-1/2 relative flex items-center">
        <CmsBannerContent
          :heading="heading"
          class="flex flex-col justify-center text-white"
        >
          <p class="tracking-wide-[0.25px] leading-[18px]">
            {{ paragraph }}
          </p>
        </CmsBannerContent>
      </div>
    </div>
  </Component>
</template>
