<script setup lang="ts">
import BannerVideo from 'cms/components/BannerVideo.vue'
import type { CarouselItem } from 'cms/types/carousel-item'

interface VisibleItems {
  [key: number]: boolean | undefined
}

const props = defineProps<{
  heading: string
  items: CarouselItem[]
  navButtonClass?: string
  indicatorActiveClass?: string
  indicatorInactiveClass?: string
  inViewportAtLoad?: boolean
}>()

const slide = ref()
const isVisible = ref<VisibleItems>({ 0: props.inViewportAtLoad })

onMounted(() => {
  const isBannerVideo = props.items.filter(
    (item) => item.component === 'BannerVideo',
  ).length

  if (isBannerVideo) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    props.items.forEach((item, idx) => {
      useIntersectionObserver(
        slide?.value[idx],
        ([{ isIntersecting }], observerElement) => {
          if (isIntersecting) {
            isVisible.value[idx] = true
            observerElement.disconnect()
          }
        },
      )
    })
  }
})
</script>

<template>
  <h2 class="sr-only text-2xl md:not-sr-only md:pb-4">
    {{ heading }}
  </h2>
  <AppSlider
    :prev-button-class="navButtonClass"
    :next-button-class="navButtonClass"
    :show-indicators="true"
    :indicator-active-class="indicatorActiveClass"
    :indicator-inactive-class="indicatorInactiveClass"
    position-buttons-outside-of-container
  >
    <div
      v-for="(item, key) in items"
      ref="slide"
      :key="key"
      class="slides-item snap-center overflow-hidden"
    >
      <BannerVideo
        v-if="
          item.component === 'BannerVideo' &&
          item.logo &&
          item.heading &&
          item.paragraph
        "
        :video="item.video"
        :logo="item.logo"
        :heading="item.heading"
        :paragraph="item.paragraph"
        :is-visible="isVisible[key]"
      />
    </div>
  </AppSlider>
</template>

<style scoped>
:deep(.slides) {
  --gap: 16px;
  gap: var(--gap);
}

.slides-item {
  --slides-number: 1;
  --visible-slides: 1;
  --spacing-per-slide: calc(var(--gap) * (var(--slides-number) - 1)) /
    var(--slides-number);
  flex-basis: calc((100% / var(--visible-slides)) - var(--spacing-per-slide));
  flex-grow: 0;
  flex-shrink: 0;
}
</style>
